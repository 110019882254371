<template>
  <v-form class="pa-5">
    <v-form
      v-show="user"
      ref="registerForm"
      v-model="validUpdate"
      lazy-validation
    >
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            dense
            outlined
            label="Iм'я"
            maxlength="20"
            :rules="[rules.required]"
            v-model="userForm.firstName"
            :value="userForm.firstName"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            dense
            outlined
            label="прізвище"
            maxlength="20"
            :rules="[rules.required]"
            v-model="userForm.lastName"
            :value="userForm.lastName"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            dense
            outlined
            label="E-mail"
            v-model="userForm.email"
            :value="userForm.email"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <!--          <v-autocomplete dense outlined label="Адреса" :filterable="false" :options="locations" @search="handleInputLocation"></v-autocomplete>-->
          <v-autocomplete
            v-model="userForm.location"
            :items="locations"
            :loading="isLoading"
            :search-input.sync="search"
            cache-items
            dense
            outlined
            hide-no-data
            hide-selected
            item-text="name"
            item-value="id"
            label="Адреса"
            return-object
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <!--          <v-text-field v-if="!userForm.phones.length" readonly dense outlined label="Телефон*" v-model="phone" :value="phone" required></v-text-field>-->
          <v-row v-if="userForm.phones">
            <v-col cols="12" v-for="(phone, key) in userForm.phones" :key="key">
              <div class="d-flex justify-space-between">
                <v-text-field
                  readonly
                  hide-details
                  dense
                  outlined
                  label="Телефон*"
                  v-model="phone.number"
                  :value="phone.number"
                  v-mask="'+38(###)-###-##-##'"
                  required
                ></v-text-field>
                <!-- <v-icon v-if="userForm.phones.length > 1" @click="deletePhone(phone)" color="error">mdi-delete</v-icon> -->
                <v-icon @click="deletePhoneByIndex(key)" color="error"
                  >mdi-delete</v-icon
                >
              </div>
            </v-col>
          </v-row>
          <modal-add-phone
            :show="dialogAddPhone"
            :currentPhones="userForm.phones"
            @add="addExtraPhone"
            @close="dialogAddPhone = false"
          />
          <v-btn
            color="warning mt-4"
            v-if="userForm.phones && userForm.phones.length < 2"
            @click="dialogAddPhone = !dialogAddPhone"
          >
            <v-icon>mdi-plus</v-icon>
            Додати телефон
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-radio-group v-model="userForm.gender" row>
            <div class="mr-2">Стать:</div>
            <v-radio
              v-for="(item, key) in genders"
              :key="key"
              :label="item.title"
              :value="item.value"
            ></v-radio>
          </v-radio-group>
        </v-col>

        <v-col class="d-flex justify-end pa-0 mb-3" cols="12">
          <v-btn x-large color="success" @click="updateProfile()"
            >Зберегти</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-form>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import cloneDeep from 'lodash.clonedeep'
const ModalAddPhone = () => import('../components/ModalAddPhone')

export default {
  name: 'InfoComponent',
  metaInfo: {
    title: 'Персональні дані'
  },
  data: () => ({
    locations: [],
    isLoading: false,
    search: null,
    userForm: {},
    validUpdate: true,
    rules: {
      required: (value) => !!value || "Обов'язковe поле"
    },
    dialogAddPhone: false,
    phone: '',
    genders: [
      { title: 'Чоловіча', value: true },
      { title: 'Жіноча', value: false }
    ]
  }),
  components: {
    ModalAddPhone
  },
  computed: {
    ...mapGetters('user', {
      user: 'getUser',
      cities: 'getCities'
    })
  },
  watch: {
    async search (val) {
      if (val.length >= 2) {
        if (this.userForm.location && val === this.userForm.location.name) {
          return
        }
        // Lazily load input items
        this.isLoading = true
        await this.fetchCities(val)
          .then(() => (this.locations = this.cities))
          .finally(() => (this.isLoading = false))
      }
    }
  },
  mounted () {
    this.userForm = cloneDeep(this.user)
    if (this.userForm.location) {
      this.locations = [this.userForm.location]
    }
  },
  methods: {
    ...mapActions('user', ['updateUserProfile', 'fetchCities']),
    ...mapMutations('user', ['setUserPhones', 'deleteUserPhone']),
    updateProfile () {
      this.updateUserProfile(this.userForm)
      this.refreshPhones()
    },
    addExtraPhone (number) {
      this.setUserPhones({ number })
      this.refreshPhones()
    },
    deletePhoneByIndex (idx) {
      this.deleteUserPhone(idx)
      this.refreshPhones()
    },
    refreshPhones () {
      this.userForm.phones = cloneDeep(this.user.phones).map((phone) => {
        if (phone.number.includes('+')) {
          return phone
        }
        const x = phone.number
          .replace(/\D/g, '')
          .match(/(\d{3})(\d{3})(\d{2})(\d{2})/)
        phone.number = `+38(${x[1]})-${x[2]}-${x[3]}-${x[4]}`
        return phone
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
